<template>
  <div class="header-container">
    <div v-if="showLabels" class="background-view" :class="this.open ? 'bg-view-on' : ''"></div>
    <div class="row">
      <img class="img" src="@/assets/gandalf.png" alt="" />
      <h2>Gandalf Ventures</h2>
    </div>
    <div class="hamburger-menu">
      <button class="navbar-toggler" type="button" @click="toggleNavbarIcon">
        <div class="animated-icon3" :class="this.open ? 'open' : ''">
          <span></span><span></span><span></span>
        </div>
      </button>
    </div>
    <div class="row menu">
      <button>About</button>
      <button>Articles</button>
      <button>Projects</button>
      <button>Contact</button>
    </div>
    <div class="labels-container" v-if="showLabels">
      <button class="ref-btn" @click="scrollToAbout">
        <h3 class="label">About</h3>
      </button>
      <button class="ref-btn" @click="scrollToArticles">
        <h3 class="label">Articles</h3>
      </button>
      <button class="ref-btn" @click="scrollToProjects">
        <h3 class="label">Projects</h3>
      </button>
      <button class="ref-btn" @click="scrollToContact">
        <h3 class="label">Contact</h3>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data() {
    return {
      open: false,
      showLabels: false,
      animate: false,
    };
  },
  methods: {
    toggleNavbarIcon() {
      this.open = !this.open;

      this.animate = !this.animate;
      if (this.animate) {
        this.$nextTick(() => {
          this.showLabels = true;
        });
      } else {
        this.showLabels = false;
      }
    },
  },
};
</script>

<style scoped>
.header-container {
  background-color: black;
  height: 100px;
  display: flex;
  align-items: center;
}
.row {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.img {
  width: 90px;
  height: 90px;
  padding: 0;
}

.menu {
  margin-left: auto;
  margin-right: 20px;
}


.labels-container {
  position: absolute;
  top: 70px;
  right: 40px;
  align-items: end;
  display: flex;
  flex-direction: column;
}

h2 {
  font-family: "Trail", sans-serif;
  color: #9f9f9f;
  font-size: 37px;
  width: fit-content;
  margin: 0;
}
button {
  font-family: "Trail", sans-serif;
  color: #9f9f9f;
  font-size: 28px;
  width: fit-content;
  margin: 0;
  background-color: transparent;
  border: 0;
  margin-left: 30px;
}

.navbar-toggler {
  top: 0 !important;
}

.hamburger-menu {
  display: none;
  margin-left: auto;
  margin-right: 50px;
}

.label {
  animation-name: example;
  animation-duration: 0.6s;
  color: #e9e8e8;
}
.label:hover {
  color: white;
}

@keyframes example {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.background-view {
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(95, 95, 95, 0.9);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
}
.bg-view-on {
  opacity: 1;
  transition: opacity 1s;
}

@media screen and (max-width: 303px) {
  .hamburger-menu {
    display: block;
    margin-right: 10px;
  }
  .menu {
    display: none;
  }
  h2 {
    font-size: 25px;
    padding: 0;
  }
  .img {
    width: 50px;
    height: 50px;
  }
  .labels-container {
    right: 0;
  }
}

@media screen and (min-width: 304px) and (max-width: 411px) {
  .hamburger-menu {
    display: block;
    margin-right: 20px;
  }
  .menu {
    display: none;
  }
  h2 {
    font-size: 28px;
    padding: 0;
  }
  .img {
    width: 60px;
    height: 60px;
  }
  .labels-container {
    right: 10px;
  }
}

@media screen and (min-width: 412px) and (max-width: 499px) {
  .hamburger-menu {
    display: block;
    margin-right: 30px;
  }
  .menu {
    display: none;
  }
  h2 {
    font-size: 30px;
  }
  .img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (min-width: 500px) and (max-width: 575px) {
  .hamburger-menu {
    display: block;
    margin-right: 40px;
  }
  .menu {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hamburger-menu {
    display: block;
  }
  .menu {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger-menu {
    display: block;
  }
  .menu {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon3 span {
  background: #9f9f9f;
}

.animated-icon3 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}

.animated-icon3.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 8px;
}
</style>