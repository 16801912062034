<template>
<div>
  <Header />
  <Top />
</div>
</template>

<script>
import Header from './components/Header.vue'
import Top from './components/Top.vue'

export default {
  name: 'App',
  components: {
    Header,
    Top
  }
}
</script>

<style>
html,
body {
  overflow-x: hidden !important;
}
#app {
  font-family: "Trail", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
