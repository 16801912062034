<template>
  <div>
    <h1>
      On a mission to build <br />
      awesome shit
    </h1>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
        <h3>Coming soon...</h3>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
        <p><a href="mailto:hi@gandalf.ventures">hi@gandalf.ventures</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Top",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kay+Pho+Du:wght@400;500;600;700&family=Roboto+Mono&family=VT323&display=swap");
h1 {
  font-family: "Trail", sans-serif;
  color: black;
  font-size: 120px;
  margin-top: 100px;
  margin-left: 100px;
}
h3 {
  font-family: "Trail", sans-serif;
  color: #9f9f9f;
  font-size: 40px;
  width: fit-content;
  margin-bottom: 0;
}
p {
  width: fit-content;
  font-family: "Roboto Mono", sans-serif;
  font-size: 25px;
  margin-left: auto;
  margin-right: 100px;
  margin-bottom: 0;
}
a {
  color: black;
}
.row {
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
}

@media screen and (max-width: 414px) {
  h1 {
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
  }
  h3 {
    font-size: 35px;
  }
  .row {
    margin-left: 20px;
  }
  p {
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 415px) and (max-width: 575px) {
  h1 {
    font-size: 70px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
  }
  .row {
    margin-left: 20px;
  }
  p {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  h1 {
    font-size: 70px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .row {
    margin-left: 50px;
  }
  p {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
  h1 {
    font-size: 80px;
    margin-left: 50px;
  }
  .row {
    margin-left: 50px;
  }
}

@media screen and (min-width: 900px) and (max-width: 991px) {
  h1 {
    font-size: 90px;
    margin-left: 70px;
  }
  .row {
    margin-left: 70px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 100px;
    margin-left: 50px;
  }
  .row {
    margin-left: 50px;
  }
}
</style>